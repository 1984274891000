define([
        'modules/upx/models/model',
        './structs/Relation'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'RelationsModule',

            object: 'Relation',
            newFromCocNumber: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newRelationFromCocNumber',parameters, ajaxOptions);
            },
            searchDataByName: function (parameters, ajaxOptions) {
                return this.callObjectMethod('searchRelationDataByName',parameters, ajaxOptions);
            },
            newWithNamespace: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newRelationWithNamespace',parameters, ajaxOptions);
            },
            newBusiness: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newBusinessRelation',parameters, ajaxOptions);
            },
            newInGroup: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newRelationInGroup',parameters, ajaxOptions);
            },
            getInGroup: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getRelationInGroup',parameters, ajaxOptions);
            },
            getId: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getRelationId',parameters, ajaxOptions);
            },
            updateInGroup: function (parameters, ajaxOptions) {
                return this.callObjectMethod('updateRelationInGroup',parameters, ajaxOptions);
            },
            updateParentId: function (parameters, ajaxOptions) {
                return this.callObjectMethod('updateRelationParentId',parameters, ajaxOptions);
            }
        });
    }
);