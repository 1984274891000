define([
        'modules/upx/collections/collection',
        '../models/Person',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'RelationsModule',
            object: 'Person',
            collection_method: 'listPersons',
            model: Model
        });
    }
);